import React, { useState } from "react";

import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import {
	Container,
	ToggleArea,
	Menu,
	ArrowDownIcon,
	InstagramIcon,
	NewsletterIcon,
	FacebookIcon,
	ImgContent,
} from "./styles";

function Toggle() {
	const { cerejeira } = useStaticQuery(graphql`
    query {
      cerejeira: file(relativePath: { eq: "common/cerejeira-menu.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
	const [menu, setMenu] = useState(false);

	const openMenu = () => {
		setMenu(!menu);
	};

	return (
		<Container>
			<ToggleArea onClick={openMenu} menu={menu}>
				<span />
				<span />
				<span />
			</ToggleArea>
			<Menu menu={menu}>
				<ImgContent fluid={cerejeira.childImageSharp.fluid} />
				<ul className="menu">
					<li>
						<Link to="/" onClick={openMenu}>
							Home
						</Link>
					</li>
					<li>
						<Link to="/sobre">Sobre</Link>
					</li>
					<li>
						<Link to="https://blog.helenacampiglia.com.br" target="_blanc">
							Blog
						</Link>
					</li>

					<li className="group">
						<input type="checkbox" name="group-4" id="group-4" />
						<label htmlFor="group-4">
							<span>
								Cursos <ArrowDownIcon />
							</span>
						</label>
						<ul className="submenu">
							<li>
								<Link target="_blanc" to="https://forms.gle/pQPhuoranKgRC25PA">
									Lista de interesse Curso Saúde da Mulher e Medicina Tradicional Chinesa - Turma de
									Inverno
								</Link>
							</li>
							<li>
								<Link
									target="_blanc"
									to="https://a13c795.paginas.digital/lp-curso-completo-saude-integrativa-e-fitoterapia-na-saude-da-mulher?src=linktre">
									Lançamento: Cursos Saúde Integrativa e Fitoterapia em Saúde da Mulher
								</Link>
							</li>

							<li>
								<Link
									target="_blanc"
									to="https://a13c795.paginas.digital/meditacao-uma-forma-de-viver?src=site">
									Curso de Meditação: Meditação um Modo de Viver
								</Link>
							</li>
							<li>
								<Link
									target="_blanc"
									to="https://a13c795.paginas.digital/aulaexclusivamenopausaemtcdrahelenacampiglia?src=site">
									Aula Fechada: Menopausa - Medicina Integrativa e MTC
								</Link>
							</li>
						</ul>
					</li>

					<li className="group">
						<input type="checkbox" name="group-1" id="group-1" />
						<label htmlFor="group-1">
							<span>
								Tratamentos <ArrowDownIcon />
							</span>
						</label>
						<ul className="submenu">
							<li>
								<Link to="/medicina-tradicional-chinesa">Medicina Tradicional Chinesa</Link>
							</li>
							<li>
								<Link to="/infertilidade">Infertilidade</Link>
							</li>
							<li>
								<Link to="/acupuntura">Acupuntura</Link>
							</li>
							<li>
								<Link to="/mente-e-corpo">Mente e Corpo</Link>
							</li>
							<li>
								<Link to="/meditacao">Meditação</Link>
							</li>
						</ul>
					</li>

					<li>
						<input type="checkbox" name="group-2" id="group-2" />
						<label htmlFor="group-2">
							<span>
								Livros <ArrowDownIcon />
							</span>
						</label>
						<ul className="submenu">
							<li>
								<Link target="_blank" to="https://amzn.to/4h0W6mm">
									Medicina Integrativa e Saúde da Mulher
								</Link>
							</li>
							<li>
								<Link to="/livros/psique-e-medicina-tradicional-chinesa">
									Psique e Medicina Tradicional Chinesa
								</Link>
							</li>
							<li>
								<Link to="/livros/dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa">
									Domínio do Yin
								</Link>
							</li>{" "}
							<li>
								<Link target="_blank" to="https://a13c795.paginas.digital/ebook-pilulas-de-conhecimento-em-saude-vol-ii">
									Pílulas de Conhecimento em Saúde. Vol II (E-book)
								</Link>
							</li>{" "}
						</ul>
					</li>

					<li>
						<Link to="/telemedicina">Telemedicina</Link>
					</li>

					<li>
						<Link to="/contato">Contato</Link>
					</li>
				</ul>
				<ul className="social">
					<li onClick={openMenu}>
						<Link to="https://www.instagram.com/helenacampiglia" target="_blanc" alt="Instagram">
							<InstagramIcon />
						</Link>
					</li>
					<li onClick={openMenu}>
						<Link to="https://www.facebook.com/drahelenacampiglia" target="_blanc" alt="Facebook">
							<FacebookIcon />
						</Link>
					</li>
					<li>
						<Link to="/newsletter" alt="Newsletter">
							<NewsletterIcon />
						</Link>
					</li>
				</ul>
			</Menu>
		</Container>
	);
}

export default Toggle;
