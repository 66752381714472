import React from "react";
/* eslint-disable max-len */

import { Link } from "gatsby";

import { Container, ArrowDownIcon } from "./styles";

function Nav({ scroll }) {
	return (
		<Container scroll={scroll}>
			<ul>
				<li>
					<Link to="/sobre">Sobre</Link>
				</li>
				<li>
					<Link to="https://blog.helenacampiglia.com.br" target="_blanc">
						Blog
					</Link>
				</li>
				<li>
					<span>
						Cursos <ArrowDownIcon />
					</span>
					<ul>
						<li>
							<Link target="_blanc" to="https://forms.gle/pQPhuoranKgRC25PA">
								Lista de interesse Curso Saúde da Mulher e Medicina Tradicional Chinesa - Turma de
								Inverno
							</Link>
						</li>
						<li>
							<Link
								target="_blanc"
								to="https://a13c795.paginas.digital/lp-curso-completo-saude-integrativa-e-fitoterapia-na-saude-da-mulher?src=linktree">
								Lançamento: Cursos Saúde Integrativa e Fitoterapia em Saúde da Mulher
							</Link>
						</li>
						<li>
							<Link
								target="_blanc"
								to="https://a13c795.paginas.digital/meditacao-uma-forma-de-viver?src=site">
								Curso de Meditação: Meditação um Modo de Viver
							</Link>
						</li>
						<li>
							<Link
								target="_blanc"
								to="https://a13c795.paginas.digital/aulaexclusivamenopausaemtcdrahelenacampiglia?src=site">
								Aula Fechada: Menopausa - Medicina Integrativa e MTC
							</Link>
						</li>
					</ul>
				</li>

				<li>
					<span>
						Tratamentos <ArrowDownIcon />
					</span>
					<ul>
						<li>
							<Link to="/medicina-tradicional-chinesa">Medicina Tradicional Chinesa</Link>
						</li>
						<li>
							<Link to="/infertilidade">Infertilidade</Link>
						</li>
						<li>
							<Link to="/acupuntura">Acupuntura</Link>
						</li>
						<li>
							<Link to="/mente-e-corpo">Mente e Corpo</Link>
						</li>
						<li>
							<Link to="/meditacao">Meditação</Link>
						</li>
					</ul>
				</li>
				<li>
					<span>
						Livros <ArrowDownIcon />
					</span>
					<ul>
						<li>
							<Link target="_blank" to="https://amzn.to/4h0W6mm">
								Medicina Integrativa e Saúde da Mulher
							</Link>
						</li>
						<li>
							<Link to="/livros/psique-e-medicina-tradicional-chinesa">
								Psique e Medicina Tradicional Chinesa
							</Link>
						</li>
						<li>
							<Link to="/livros/dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa">
								Domínio do Yin
							</Link>
						</li>
						<li>
							<Link target="_blank" to="https://a13c795.paginas.digital/ebook-pilulas-de-conhecimento-em-saude-vol-ii">
								Pílulas de Conhecimento em Saúde. Vol II (E-book)
							</Link>
						</li>
					</ul>
				</li>
				<li>
					<Link to="/telemedicina">Telemedicina</Link>
				</li>

				<li>
					<Link to="/contato">Contato</Link>
				</li>
			</ul>
		</Container>
	);
}

export default Nav;
